
type Device = {
    minMediaQuery: string;
    maxMediaQuery: string;
    minScreenWidth: number;
    maxScreenWidth: number;
};

type Devices = {
    mobileS: Device;
    mobileM: Device;
    mobileL: Device;
    tablet: Device;
    laptop: Device;
    laptopL: Device;
    desktop: Device;
};

const minScreenWidths = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560
};

const createDevice = (minScreenWidth: number, maxScreenWidth: number): Device => ({
    minMediaQuery: `(min-width: ${minScreenWidth}px)`,
    maxMediaQuery: `(max-width: ${maxScreenWidth}px)`,
    minScreenWidth,
    maxScreenWidth
});
  
export const devices: Devices = {
    mobileS: createDevice(minScreenWidths.mobileS, minScreenWidths.mobileM),
    mobileM: createDevice(minScreenWidths.mobileM, minScreenWidths.mobileL),
    mobileL: createDevice(minScreenWidths.mobileL ,minScreenWidths.tablet),
    tablet: createDevice(minScreenWidths.tablet, minScreenWidths.laptop),
    laptop: createDevice(minScreenWidths.laptop, minScreenWidths.laptopL),
    laptopL: createDevice(minScreenWidths.laptopL, minScreenWidths.desktop),
    desktop: createDevice(minScreenWidths.desktop, null),
};

export const devicePixelRatio = window.devicePixelRatio;
