import React from "react";
import { render } from "react-dom";

import "~/common/style/css/font.css";

import LandingPageContainer from "~/landing/containers/LandingPage";

render(
    <LandingPageContainer/>,
    document.getElementById("app")
);
