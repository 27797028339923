import React from "react";
import styled, { keyframes } from "styled-components";

import svgLogo from "~/web/svgs/gifuffle-logo.svg";
import { color } from "~/common/style/Color";

const appear = keyframes`
    0% {
        opacity: 0;
    }
    49% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`;

const cycleColor = keyframes`
    0% {
        fill: ${color.pallet.red};
    }
    16% {
        fill: ${color.pallet.red};
    }
    17% {
        fill: ${color.pallet.yellow};
    }
    32% {
        fill: ${color.pallet.yellow};
    }
    33% {
        fill: ${color.pallet.green};
    }
    49% {
        fill: ${color.pallet.green};
    }
    50% {
        fill: ${color.pallet.blue};
    }
    66% {
        fill: ${color.pallet.blue};
    }
    67% {
        fill: ${color.pallet.purple};
    }
    82% {
        fill: ${color.pallet.purple};
    }
    83% {
        fill: ${color.pallet.pink};
    }
    99% {
        fill: ${color.pallet.pink};
    }
    100% {
        fill: ${color.pallet.red};
    }
`;

const AnimatedLogo = styled(svgLogo)`
    display: block;
    margin: auto;
    width: 440px;
    height: 180px;

    .letter {
        animation: ${cycleColor} infinite 3s linear;
        animation-fill-mode: forwards;
    }
    .dot {
        animation: ${appear} infinite 1.5s linear;
        animation-fill-mode: backwards;
    }
    .G, .dot1 {
        animation-delay: 0s;
    }
    .I {
        animation-delay: -2.75s;
    }
    .F {
        animation-delay: -2.5s;
    }
    .dot2 {
        animation-delay: 0.25s;
    }
    .dot3 {
        animation-delay: 0.5s;
    }
    .letter:hover {
        fill: white !important;
    }
    .letter:active {
        fill: black !important;
    }
    .uffle:hover {
        fill: black;
    }
    .uffle:active {
        fill: white;
    }
`;

type LogoProps = {
    containerStyle?: React.CSSProperties;
    style?: React.CSSProperties;
};

const GifuffleLogo: React.FC<LogoProps> = ({ containerStyle, style }: LogoProps) => {
    return (
        <div style={containerStyle}>
            <AnimatedLogo alt={"Gifuffle logo"} style={style}/>
        </div>
    );
};

export default GifuffleLogo;
