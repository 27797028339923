const offWhite = "#f8f8f8";
const black = "#181818";

const whiteSmoke = "#e8e8e8";
const lightGrey = "#d3d3d3";
const grey = "#808080";
const darkGrey = "#383838";

const babyBlue = {
    value: "#e7f2f8",
    shades: [
        "#cfd9df",
        "#b8c1c6",
        "#a1a9ad",
        "#8a9194",
        "#73797c",
        "#5c6063",
        "#45484a",
        "#2e3031",
        "#171818"
    ],
    tints: [
        "#e9f3f8",
        "#ebf4f9",
        "#eef5fa",
        "#f0f7fa",
        "#f3f8fb",
        "#f5f9fc",
        "#f7fbfc",
        "#fafcfd",
        "#fcfdfe",
    ],
    monochromatic: [
        "#acd2e7",
        "#c0ddec",
        "#d3e7f2",
        "#e7f2f8",
        "#fbfdfe"
    ]
};

const red = {
    value: "#fa8072",
    shades: [
        "#e17366",
        "#c8665b",
        "#af594f",
        "#964c44",
        "#7d4039",
        "#64332d",
        "#4b2622",
        "#321916",
        "#190c0b"
    ],
    tints: [
        "#fa8c80",
        "#fb998e",
        "#fba69c",
        "#fcb2aa",
        "#fcbfb8",
        "#fdccc6",
        "#fdd8d4",
        "#fee5e2",
        "#fef2f0"
    ],
    monochromatic: [
        "#f73d28",
        "#f85441",
        "#f96a59",
        "#fa8072",
        "#fb968b",
        "#fcaca3",
        "#fdc3bc"
    ]
};

const yellow = {
    value: "#fae072",
    shades: [
        "#e1c966",
        "#c8b35b",
        "#af9c4f",
        "#968644",
        "#7d7039",
        "#64592d",
        "#4b4322",
        "#322c16",
        "#19160b"
    ],
    tints: [
        "#fae380",
        "#fbe68e",
        "#fbe99c",
        "#fcecaa",
        "#fcefb8",
        "#fdf2c6",
        "#fdf5d4",
        "#fef8e2",
        "#fefbf0"
    ],
    monochromatic: [
        "#f7d028",
        "#f8d541",
        "#f9db59",
        "#fae072",
        "#fbe58b",
        "#fceba3",
        "#fdf0bc"
    ]
};

const green = {
    value: "#72fa8c",
    shades: [
        "#66e17e",
        "#5bc870",
        "#4faf62",
        "#449654",
        "#397d46",
        "#2d6438",
        "#224b2a",
        "#16321c",
        "#0b190e"
    ],
    tints: [
        "#80fa97",
        "#8efba3",
        "#9cfbae",
        "#aafcba",
        "#b8fcc5",
        "#c6fdd1",
        "#d4fddc",
        "#e2fee8",
        "#f0fef3"
    ],
    monochromatic: [
        "#28f750",
        "#41f864",
        "#59f978",
        "#72fa8c",
        "#8bfba0",
        "#a3fcb4",
        "#bcfdc8"
    ]
};

const blue = {
    value: "#72dffa",
    shades: [
        "#66c8e1",
        "#5bb2c8",
        "#4f9caf",
        "#448596",
        "#396f7d",
        "#2d5964",
        "#22424b",
        "#162c32",
        "#0b1619"
    ],
    tints: [
        "#80e2fa",
        "#8ee5fb",
        "#9ce8fb",
        "#aaebfc",
        "#b8effc",
        "#c6f2fd",
        "#d4f5fd",
        "#e2f8fe",
        "#f0fbfe"
    ],
    monochromatic: [
        "#28cef7",
        "#41d4f8",
        "#59d9f9",
        "#72dffa",
        "#8be5fb",
        "#a3eafc",
        "#bcf0fd"
    ]
};

const purple = {
    value: "#728cfa",
    shades: [
        "#667ee1",
        "#5b70c8",
        "#4f62af",
        "#445496",
        "#39467d",
        "#2d3864",
        "#222a4b",
        "#161c32",
        "#0b0e19"
        
    ],
    tints: [
        "#8097fa",
        "#8ea3fb",
        "#9caefb",
        "#aabafc",
        "#b8c5fc",
        "#c6d1fd",
        "#d4dcfd",
        "#e2e8fe",
        "#f0f3fe"
    ],
    monochromatic: [
        "#2850f7",
        "#4164f8",
        "#5978f9",
        "#728cfa",
        "#8ba0fb",
        "#a3b4fc",
        "#bcc8fd"
    ]
};

const pink = {
    value: "#fa72cc",
    shades: [
        "#e166b7",
        "#c85ba3",
        "#af4f8e",
        "#96447a",
        "#7d3966",
        "#642d51",
        "#4b223d",
        "#321628",
        "#190b14"
    ],
    tints: [
        "#fa80d1",
        "#fb8ed6",
        "#fb9cdb",
        "#fcaae0",
        "#fcb8e5",
        "#fdc6ea",
        "#fdd4ef",
        "#fee2f4",
        "#fef0f9"
    ],
    monochromatic: [
        "#f728b1",
        "#f841ba",
        "#f959c3",
        "#fa72cc",
        "#fb8bd5",
        "#fca3de"
    ]
};

type StateColors = {
    background: string;
    text: string;
    border: string;
};

const StateColors = (background: string, text: string, border?: string): StateColors => {
    return {
        background,
        text,
        border: border ? border : text
    };
};

type ButtonColors = {
    default: StateColors;
    hover: StateColors;
    active: StateColors;
    disabled: StateColors;
};

const ButtonColors = (
    def: StateColors,
    hover: StateColors,
    active: StateColors,
    disabled: StateColors): ButtonColors => ({
    default: def, hover, active, disabled
});

const primary = StateColors(babyBlue.value, black, babyBlue.shades[6]);

const ColoredButton = (color: any): ButtonColors => (ButtonColors(
    StateColors(color.shades[0], offWhite, primary.border),
    StateColors(color.shades[0], offWhite, primary.border),
    StateColors(color.shades[1], offWhite, color.shades[3]),
    StateColors(babyBlue.shades[1], offWhite, babyBlue.shades[1]))
);
 
export const color = {
    background: primary.background,
    text: primary.text,
    header: StateColors(offWhite, primary.text, primary.border),
    contentBox: StateColors(offWhite, primary.text, primary.border),
    button: {
        default: ButtonColors(
            StateColors(offWhite, primary.border),
            StateColors(offWhite, primary.border),
            StateColors(babyBlue.shades[0], babyBlue.shades[4], babyBlue.shades[2]),
            StateColors(babyBlue.shades[1], offWhite, babyBlue.shades[1])),
        action: ColoredButton(blue),
        red: ColoredButton(red),
        yellow: ColoredButton(yellow),
        green: ColoredButton(green),
        blue: ColoredButton(blue),
        purple: ColoredButton(purple),
        pink: ColoredButton(pink)
    },
    field: StateColors(babyBlue.tints[6], babyBlue.shades[6], babyBlue.shades[3]),
    focus: babyBlue.shades[6],
    pallet: {
        red: red.value,
        yellow: yellow.value,
        green: green.value,
        blue: blue.value,
        purple: purple.value,
        pink: pink.value,
    }
};
