// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Gifuffle.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n@font-face {\r\n    font-family: \"Gifuffle\";\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\r\n}\r\n\r\n* {\r\n    box-sizing: border-box;\r\n}\r\n\r\nbody {    \r\n    font-family: sans-serif;\r\n}\r\n", "",{"version":3,"sources":["font.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;IACvB,+DAA6C;AACjD;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B","file":"font.css","sourcesContent":["\r\n@font-face {\r\n    font-family: \"Gifuffle\";\r\n    src: url(\"./Gifuffle.otf\") format(\"opentype\");\r\n}\r\n\r\n* {\r\n    box-sizing: border-box;\r\n}\r\n\r\nbody {    \r\n    font-family: sans-serif;\r\n}\r\n"]}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
