import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { IoIosVolumeOff, IoIosVolumeHigh } from "react-icons/io";

import { useAudio } from "~/common/components/Audio";

import GifuffleLogo from "~/common/components/images/GifuffleLogo";
import Footer from "~/landing/components/Footer";

import { color } from "~/common/style/Color";

const PageContainer = styled.div`
    align-items: stretch;
    background-color: ${color.background};
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

const ContentContainer = styled.div`
    flex-grow: 1;
    position: relative;
`;

const VolumeButtonContainer = styled.div`
    padding-bottom: 40px;
`;

const CenteredContainer = styled.div`
    left: 0;
    margin: 0 auto;
    max-width: 800px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-40%);
    width: 100%;
`;

const StyledText = styled.div`
    display: inline-block;
    font-family: gifuffle;
    font-size: 10vw;
    margin: 7vw auto 0 auto;

    @media (min-width: 820px) {
        font-size: 82px;
        margin-top: 57px;
    }
`;

const StyledVolumeButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    outline: none;
    padding: 0;
`;

const Landing: React.FC = () => {
    const audio = useAudio({
        autoPlay: false,
        logEvents: false,
        loop: true,
        url: "/assets/audio/theme.mp3"
    });
    const [isPlaying, setIsPlaying] = useState(audio.playing);

    useEffect(() => {
        setIsPlaying(audio.playing);
    }, [audio.playing]);
    
    const toggleAudio = (): void => {
        audio.toggle();
    };

    const getAudioIcon = (): React.ReactElement => {
        return (!isPlaying)
        ? <IoIosVolumeOff/>
        : <IoIosVolumeHigh/>;
    };

    return (
        <PageContainer>
            <ContentContainer>
                <CenteredContainer>
                    <GifuffleLogo
                        style={{
                            width: "80%",
                            height: "auto"
                        }}/>
                    <StyledText>{"Coming Soon..."}</StyledText>
                </CenteredContainer>
            </ContentContainer>
            <VolumeButtonContainer>
                <StyledVolumeButton onClick={toggleAudio}>
                        <IconContext.Provider value={{
                            size: "40px",
                            style: {
                                fill: "black"
                            }
                        }}>
                            {getAudioIcon()}
                        </IconContext.Provider>
                    </StyledVolumeButton>
                </VolumeButtonContainer>
            <Footer/>
        </PageContainer>
    );
};

export default Landing;
