import React from "react";
import styled from "styled-components";
import { SocialIcon } from "react-social-icons";

import { color } from "~/common/style/Color";
import { devices } from "~/common/style/screen";

const StyledFooter = styled.footer`
    color: ${color.text};
    flex-shrink: 0;
    margin: 0;
`;

const FooterText = styled.p`
    font-family: monospace;
    font-size: 1em;
    font-weight: lighter;
    margin: 10px 0;

    @media ${devices.mobileL.maxMediaQuery} {
        font-size: 0.8em;
        margin-bottom: 0;
    }
`;

const PaddedSocialIcon = styled(SocialIcon)`
    margin: 8px;
    width: 35px !important;
    height: 35px !important;

    @media ${devices.mobileL.maxMediaQuery} {
        margin: 6px;
        width: 20px !important;
        height: 20px !important;
    }
`;

const PaddedInstagramIcon = styled(SocialIcon)`
    margin: 8px;
    width: 35px !important;
    height: 35px !important;

    @media ${devices.mobileL.maxMediaQuery} {
        margin: 6px;
        width: 20px !important;
        height: 20px !important;
    }

    .social-svg-mask {
        fill: #F56035 !important;
    }
`;

const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <PaddedSocialIcon url="https://facebook.com/gifuffle"/>
            <PaddedSocialIcon url="https://twitter.com/gifuffle"/>
            <PaddedInstagramIcon url="https://instagram.com/gifuffle"/>
            <FooterText>{"Gifuffle "} © {new Date().getFullYear()}</FooterText>
        </StyledFooter>
    );
};

export default Footer;
